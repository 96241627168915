<template>
  <div class="view-wrap">
    <el-form :model="searcForm" class="F">
      <el-form-item label="客户:" style="width: 270px">
        <el-select v-model="searcForm.customerId" placeholder="" clearable>
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="item in customData"
            :key="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="录单日期" style="width: 500px">
        <el-date-picker
          v-model="pickerDate"
          type="daterange"
          range-separator="至"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          :picker-options="pickerOptions"
          end-placeholder="结束日期"
          :clearable="false"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <div class="F">
          <el-button type="primary" @click="searchForm">查询</el-button>
          <el-button type="primary" @click="handleDownload" plain
            >导出</el-button
          >
        </div>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      border
      show-summary
      :summary-method="getSummaries"
      style="width: 100%"
    >
      <el-table-column prop="code" label="客户编号"></el-table-column>
      <el-table-column prop="name" label="客户名称"></el-table-column>
      <el-table-column prop="entryDate" label="录单日期"></el-table-column>
      <el-table-column prop="type" label="单据类型"></el-table-column>
      <el-table-column prop="no" label="单据号"></el-table-column>
      <el-table-column prop="payAmount" label="应付款变动"></el-table-column>
      <el-table-column prop="balance" label="应付款余额"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { datatimestatus } from "@/until/auth";
import { getCusDetail } from "@/api/finance";
import { GetCustomerList } from "@/api/customer";
export default {
  data() {
    return {
      searcForm: {
        pageNum: 1,
        pageSize: 20,
        customerId: "",
        startDate: "",
        endDate: "",
      },
      tableData: [],
      pickerDate: [],
      customData: [],
      pickerOptions: null,
    };
  },
  created() {
    this.GetCustomerList();
    this.getCusDetail();
    this.getDate();
    this.datatimestatus();
  },
  watch: {
    pickerDate(val) {
      if (!val) {
        this.searcForm.startDate = "";
        this.searcForm.endDate = "";
      } else {
        this.searcForm.startDate = val[0];
        this.searcForm.endDate = val[1];
      }
    },
  },
  methods: {
    async GetCustomerList() {
      let res = await GetCustomerList();
      if (res.code == 1) {
        this.customData = res.data.records;
        this.searcForm.customerId = res.data.records[0].id;
      }
    },
    async getCusDetail() {
      let params = this.searcForm;
      let res = await getCusDetail(params);
      if (res.code == 1) {
        this.tableData = res.data.records;
      }
    },
    searchForm() {},
    handleDownload() {},
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = "总价";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += " 元";
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
    // 默认时间
    getDate() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month =
        now.getMonth() + 1 >= 10
          ? now.getMonth() + 1
          : "0" + (now.getMonth() + 1); //得到月份
      var date = now.getDate() >= 10 ? now.getDate() : "0" + now.getDate(); //得到日期
      if (this.openAccountFlag) {
        var reg = sessionStorage.getItem("createDate").split(" ")[0];
        var defaultDate = [`${reg}`, `${year}-${month}-${date}`];
        this.searcForm.startDate = `${reg}`;
        this.searcForm.endDate = `${year}-${month}-${date}`;
      } else {
        var defaultDate = [`${year}-${month}-01`, `${year}-${month}-${date}`];
        this.searcForm.startDate = `${year}-${month}-01`;
        this.searcForm.endDate = `${year}-${month}-${date}`;
      }
      this.pickerDate = defaultDate;
    },
    datatimestatus() {
      this.pickerOptions = datatimestatus();
    },
  },
};
</script>

<style lang='scss' scoped>
.F {
  display: flex;
}
</style>